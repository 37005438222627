import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { initialStateUserInterface } from '../types/types';
import { condofy } from '../api/base';
import { stringCleaner } from '../utils';
import axios from 'axios';

const initialState: initialStateUserInterface = {
  cpf: '',
  cnpj: '',
  legalName: '',
  tradeName: '',
  code: 'STARTER',
  typeId: null,
  state: '',
  city: '',
  district: '',
  street: '',
  zipCode: '',
  number: '',
  goal: null,
  paymentSubscriptionId: '',
  paymentGateway: 'STRIPE',
  role: null,
  userUnit: null,
  units: [],
  agreeUseTerms: false,
  numberOfUnits: null,
  blocks: null,
  floors: null,
  blockIndication: null,
  blockIndicationBoolean: null,
  firstUnitNumber: null,
  unitPattern: null,
  unitsPerFloor: null,
  unitNomenclature: '',
  doNotKnowCnpj: false,
  customCondoName: '',
  fullAddress: '',
};

interface AddUnitPayload {
  block: string;
  number: string;
  typeId: null|number;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDoNotKnowCnpj: (state, action) => {
      state.doNotKnowCnpj = action.payload;
    },
    onFullAddressChange: (state, action) => {
      state.fullAddress = action.payload;
    },
    onCustomCondoNameChange: (state, action) => {
      state.customCondoName = action.payload;
    },
    logoutUser: state => {
      state.cpf = '';
      state.cnpj = '';
      state.legalName = '';
      state.tradeName = '';
      state.code = 'STARTER';
      state.typeId = null;
      state.state = '';
      state.city = '';
      state.district = '';
      state.street = '';
      state.zipCode = '';
      state.number = '';
      state.goal = null;
      state.paymentSubscriptionId = '';
      state.paymentGateway = 'STRIPE';
      state.role = null;
      state.userUnit = null;
      state.units = [];
      state.agreeUseTerms = false;
      state.numberOfUnits = null;
      state.blocks = null;
      state.floors = null;
      state.blockIndication = null;
      state.blockIndicationBoolean = null;
      state.firstUnitNumber = null;
      state.unitPattern = null;
      state.unitsPerFloor = null;
      state.unitNomenclature = '';
    },
    setCnpjInfos: (state, action) => {
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.street = action.payload.street;
      state.zipCode = action.payload.zipCode;
      state.number = action.payload.number;
      state.district = action.payload.district;
    },
    onUserUnitChange: (state, action) => {
      state.userUnit = action.payload;
    },
    setUserUnityToAdm: state => {
      state.userUnit = { block: '', number: 'ADM', typeId: 99 };
    },
    unsetUserUnityToAdm: state => {
      state.userUnit = null;
      state.units = state.units.filter(unit => unit.number !== 'ADM');
    },
    onUnitAdd: (state, action: PayloadAction<AddUnitPayload>) => {
      state.units.push(action.payload);
    },
    onUnitEdit: (state, action) => {
      const index = state.units.findIndex(
        unit =>
          unit.number === action.payload.number &&
          unit.block === action.payload.block &&
          unit.typeId === action.payload.typeId
      );

      if (index !== -1) {
        state.units[index] = {
          number: action.payload.newNumber,
          block: action.payload.block,
          typeId: action.payload.typeId
        };
      }
    },
    onUnitDelete: (state, action) => {
      const index = state.units.findIndex(
        unit =>
          unit.number === action.payload.number &&
          unit.block === action.payload.block
      );

      if (index !== -1) {
        state.units.splice(index, 1);
      }
    },
    onUnitsChange: (state, action) => {
      state.units = action.payload;
    },
    onUnitNomenclatureChange: (state, action) => {
      state.unitNomenclature = action.payload;
    },
    onUnitsPerFloorChange: (state, action) => {
      state.unitsPerFloor = action.payload;
    },
    onUnitPatternChange: (state, action) => {
      state.unitPattern = action.payload;
    },
    onFirstUnitNumberChange: (state, action) => {
      state.firstUnitNumber = action.payload;
    },
    onBlockIndicationChange: (state, action) => {
      state.blockIndication = action.payload;
    },
    onBlockIndicationBooleanChange: (state, action) => {
      state.blockIndicationBoolean = action.payload;
    },
    onCpfChange: (state, action) => {
      state.cpf = action.payload;
    },
    onRoleChange: (state, action) => {
      state.role = action.payload;
    },
    onGoalChange: (state, action) => {
      state.goal = action.payload;
    },
    onCnpjChange: (state, action) => {
      state.cnpj = action.payload;
    },
    onNumberChange: (state, action) => {
      state.number = action.payload;
    },
    onNumberOfUnitsChange: (state, action) => {
      state.numberOfUnits = action.payload;
    },
    onTypeIdChange: (state, action) => {
      state.typeId = action.payload;
    },
    onAgreeChange: (state, action) => {
      state.agreeUseTerms = action.payload;
    },
    onBlocksChange: (state, action) => {
      state.blocks = action.payload;
    },
    onFloorsChange: (state, action) => {
      state.floors = action.payload;
    },
    onCepChange: (state, action) => {
      state.zipCode = action.payload;
    },
    onCityChange: (state, action) => {
      state.city = action.payload;
    },
    onDistrictChange: (state, action) => {
      state.district = action.payload;
    },
    onStateChange: (state, action) => {
      state.state = action.payload;
    },
    onStreetChange: (state, action) => {
      state.street = action.payload;
    },
    onCleanCnpjInfos: (state, action) => {
      state.city = '';
      state.state = '';
      state.street = '';
      state.zipCode = '';
      state.number = '';
      state.district = '';
      state.legalName = '';
      state.tradeName = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getCnpjInfo.fulfilled, (state, action) => {
      console.log(action.payload?.data.data);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.data &&
        action.payload.data.data.address
      ) {
        state.city = action.payload.data.data.address.city;
        state.state = action.payload.data.data.address.state;
        state.street = action.payload.data.data.address.street;
        state.zipCode = action.payload.data.data.address.zipCode;
        state.number = action.payload.data.data.address.number;
        state.district = action.payload.data.data.address.district;
        state.legalName = action.payload.data.data.legalName;
        state.tradeName = action.payload.data.data.tradeName;
      }
    });
    builder.addCase(getAddressByCep.fulfilled, (state, action) => {
      if (action.payload) {
        state.city = action.payload.data.localidade;
        state.state = action.payload.data.uf;
        state.street = action.payload.data.logradouro;
        state.district = action.payload.data.bairro;
        state.zipCode = action.payload.data.cep;
      }
    });
    builder.addCase(checkStripeStatus.fulfilled, (state, action) => {
      console.log(action.payload?.data);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.status === 'complete'
      ) {
        console.log(action.payload);
        state.paymentSubscriptionId = action.payload.data.subscriptionId;
      }
    });
  },
});

export const user = (state: RootState) => state.user;

export const {
  onCpfChange,
  onGoalChange,
  onRoleChange,
  onCnpjChange,
  onCepChange,
  onNumberChange,
  onCityChange,
  onDistrictChange,
  onStateChange,
  onStreetChange,
  onNumberOfUnitsChange,
  onCleanCnpjInfos,
  onAgreeChange,
  onTypeIdChange,
  onBlocksChange,
  onFloorsChange,
  onBlockIndicationChange,
  onBlockIndicationBooleanChange,
  onFirstUnitNumberChange,
  onUnitPatternChange,
  onUnitsPerFloorChange,
  onUnitNomenclatureChange,
  onUnitsChange,
  onUnitAdd,
  onUnitEdit,
  onUnitDelete,
  onUserUnitChange,
  setUserUnityToAdm,
  unsetUserUnityToAdm,
  logoutUser,
  onCustomCondoNameChange,
  setDoNotKnowCnpj,
  setCnpjInfos,
  onFullAddressChange,
} = userSlice.actions;

export const getCnpjInfo = createAsyncThunk(
  'auth-user/getCnpjInfo',
  async ({ cnpj }: { cnpj: string }) => {
    try {
      let response = await condofy.get(
        `/v1/companies/${stringCleaner(cnpj, 14)}`
      );
      return response;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const checkStripeStatus = createAsyncThunk(
  'auth-user/checkStripeStatus',
  async ({ session_id }: { session_id: string }) => {
    try {
      let response = await condofy.get(`/stripe/sessions/${session_id}`);
      return response;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAddressByCep = createAsyncThunk(
  'user/getAddressByCep',
  async ({ zipCode }: { zipCode: string }) => {
    try {
      const response = await condofy.get(`https://viacep.com.br/ws/${zipCode}/json/`);
      if (response.data.erro !== undefined) {
        return null;
      }
      return response;
    } catch (error: any) {
      console.error(error);
    }
  }
);

export default userSlice.reducer;
