/* eslint-disable react-hooks/exhaustive-deps */
import { Header, SubHeader } from '../../@condofy-components/typoFonts';
import { MainContainer } from './style';
import Lottie from 'react-lottie';
import animationData from '../../img/building-loading.json';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { BuildingCompleted } from '../../@condofy-components/localIcons';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, finishCondominium } from '../../redux/authSlice';
import { concludeSteps } from '../../redux/stepperSlice';
import { user } from '../../redux/userSlice';
import { onActiveAlertError } from '../../redux/alertSlice';
import { NextButton } from '../../@condofy-components/nextButton';
import { stringCleaner } from '../../utils';
import styled from 'styled-components';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin-top: 4;
  @media (max-width: 900px) {
    height: 80%;
    width: 100%;
    text-align: center;
    justify-content: space-evenly;
  }
`;

const FixedHeightMainContainer = styled(MainContainer)`
  height: 100vh;
`;

export const IsBuilding = () => {
  const { creatingLoading } = useAppSelector(auth);
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    agreeUseTerms,
    city,
    cnpj,
    legalName,
    tradeName,
    district,
    goal,
    number,
    state,
    paymentGateway,
    paymentSubscriptionId,
    userUnit,
    street,
    zipCode,
    typeId,
    cpf,
    units,
    role,
    doNotKnowCnpj,
    customCondoName,
  } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);
  const [animationDimensions, setAnimationDimensions] = useState({
    height: '460px',
    width: '410px',
  });

  const adjustAnimationDimensions = () => {
    let newWidth = '410px';
    let newHeight = '460px';

    // Adjust width and height based on window width
    if (window.innerWidth <= 785 && window.innerWidth > 394) {
      newWidth = '390px';
      newHeight = '440px';
    } else if (window.innerWidth <= 394) {
      newWidth = '310px';
      newHeight = '360px';
    }

    if (window.innerHeight >= 690 && window.innerHeight <= 720) {
      newWidth = '300px';
      newHeight = '350px';
    } else if (window.innerHeight >= 0 && window.innerHeight <= 690) {
      newWidth = '250px';
      newHeight = '300px';
    }
    setAnimationDimensions({ height: newHeight, width: newWidth });
  };

  useEffect(() => {
    window.addEventListener('resize', adjustAnimationDimensions);
    adjustAnimationDimensions();
    return () =>
      window.removeEventListener('resize', adjustAnimationDimensions);
  }, []);

  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const buildCondominium = () => {
    setApiFailed(false);
    if (!goal || !userUnit || !typeId || !role) {
      return;
    }
    dispatch(
      finishCondominium({
        agreeUseTerms,
        city,
        cnpj: doNotKnowCnpj ? '99999999999999' : stringCleaner(cnpj, 16),
        condominiumName: doNotKnowCnpj
          ? customCondoName
          : legalName
          ? legalName
          : tradeName
          ? tradeName
          : '',
        district,
        goal,
        number,
        state,
        paymentGateway,
        paymentSubscriptionId,
        userUnit,
        street,
        zipCode,
        typeId,
        socialSecurityNumber: stringCleaner(cpf, 11),
        units,
        userTypeId: role,
      })
    )
      .then((response: any) => {
        if (response.payload) {
          setFirstLoad(false);
          console.log(response);
          setShowButton(true);
          dispatch(concludeSteps());
        } else {
          setApiFailed(true);
        }
      })
      .catch((error: any) => {
        setFirstLoad(false);
        dispatch(
          onActiveAlertError(
            'Erro ao criar o condomínio, entre em contato com o suporte.'
          )
        );
      });
  };

  useEffect(() => {
    setTimeout(() => buildCondominium(), 800);
  }, []);
  
  return (
    <FixedHeightMainContainer>
      {creatingLoading || firstLoad ? (
        <Box
          height={animationDimensions.height}
          width={animationDimensions.width}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'end'}
        >
          <Lottie
            key={1}
            options={options}
            playMode="bounce"
            height={animationDimensions.height}
            width={animationDimensions.width}
          />
        </Box>
      ) : (
        <Box
          height={animationDimensions.height}
          width={animationDimensions.width}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'end'}
        >
          <BuildingCompleted />
        </Box>
      )}
      <FlexBox sx={{ margin: '2vh 0' }}>
        <Header style={{ margin: 0 }}>
          {creatingLoading || firstLoad
            ? 'Aguarde, estamos quase lá'
            : apiFailed
            ? 'Algo deu errado, tente novamente'
            : 'Parabéns seu condomínio esta pronto'}
        </Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          {creatingLoading || firstLoad
            ? 'Estamos construindo seu novo Condomínio na Condofy, em breve você terá todo o controle do seu condomínio na sua tela.'
            : apiFailed
            ? 'Se caso o erro persistir, entrar em contato com o suporte.'
            : 'Você tera acesso ao seu painel agora, convide os moradores e agilize a gestão do seu condomínio.'}
        </SubHeader>
      </FlexBox>
      {showButton && !apiFailed && (
        <Box>
          <NextButton
            single
            onClick={() => {
              window.location.href = String(process.env.REACT_APP_AUTH_BASE_URL);
            }}
            disabled={!showButton}
            disappear={false}
            customText="Continuar"
            lastStep={false}
          />
        </Box>
      )}
    </FixedHeightMainContainer>
  );
};
